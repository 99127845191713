import { type GetStaticPropsResult } from 'next'
import dynamic from 'next/dynamic'

import { homePageModuleQuery } from '@data/sanity/queries/page'
import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { type SanityHomePage } from '@data/sanity/queries/types/page'
import { getPageSanityClient } from '@lib/sanity/client'
import { defaultModuleQueryParameters, getHomePage } from '@lib/sanity/page'
import { type Locale } from '@lib/language'
import { getNonNullValues } from '@lib/page'
import { type CustomStaticPropsContext } from '@lib/routes'
import { getOrganizationSchema } from '@lib/schema/organization'
import { getVideoObjectSchemas } from '@lib/schema/video'
import { getWebPageSchema, getWebSiteSchema } from '@lib/schema/web'

import Layout from '@components/layout'
import Modules from '@modules/modules'
import PreviewModules from '@modules/preview-modules'

const PreviewProvider = dynamic(() => import('@lib/sanity/preview'))

interface HomePageProps {
  draftMode: boolean
  draftToken?: string
  locale: Locale
  page: SanityHomePage
  site: SanitySiteFragment
}

const HomePage = ({
  draftMode,
  draftToken,
  locale,
  page,
  site,
}: HomePageProps) => {
  const organizationSchema = getOrganizationSchema(site)
  const webSiteSchema = getWebSiteSchema(site)
  const webPageSchema = getWebPageSchema(page, site)
  const videoObjectSchemas = getVideoObjectSchemas(page)
  const schemas = [
    organizationSchema,
    webSiteSchema,
    webPageSchema,
    ...videoObjectSchemas,
  ]

  if (draftMode && draftToken) {
    return (
      <Layout page={page} site={site} schemas={schemas} draftMode>
        <PreviewProvider draftToken={draftToken}>
          <PreviewModules
            modules={page.modules}
            moduleQuery={homePageModuleQuery}
            moduleQueryParams={{
              ...defaultModuleQueryParameters,
              locale,
            }}
          />
        </PreviewProvider>
      </Layout>
    )
  }

  return (
    <Layout page={page} site={site} schemas={schemas}>
      <Modules modules={page.modules} />
    </Layout>
  )
}

export async function getStaticProps(
  context: CustomStaticPropsContext
): Promise<GetStaticPropsResult<HomePageProps>> {
  const [sanityClient, draftMode, draftToken] = getPageSanityClient(
    context.draftMode
  )
  const homePage = await getHomePage(sanityClient, context.locale)

  if (!homePage.page) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      draftMode,
      draftToken,
      locale: context.locale,
      page: getNonNullValues(homePage.page),
      site: getNonNullValues(homePage.site),
    },
    revalidate: 60,
  }
}

export default HomePage
